import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Attendee, Course } from '@hor/data-access-shared/models/thebracespace.model';
import { AuthActions } from '@hor/data-access-shared/states/auth/auth.action';
import { AuthState } from '@hor/data-access-shared/states/auth/auth.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';

@Component({
  selector: 'hor-registration-container',
  templateUrl: './registration-container.component.html',
  styleUrls: ['./registration-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationContainerComponent implements OnInit {

  @Select(AuthState.getCourse)
  course$: Observable<Course>;

  @Select(AuthState.getIsLoading)
  isLoading$: Observable<boolean>;


  @Select(AuthState.getIsRegistered)
  isRegistered$: Observable<boolean>;


  @Select(AuthState.getAttendees)
  attendees$: Observable<Array<Attendee>>;

  constructor(private http: HttpClient, private loginService: LoginService, private store: Store,) { }

  ngOnInit(): void {
    this.loginService.login().subscribe(
      (data: any) => {
        sessionStorage.setItem('hor-token', 'Bearer ' + data.token);
        localStorage.setItem('hor-login', Date.now().toString());
        localStorage.removeItem('hor-login');
        return this.http.get('https://api.thebracespace.com/api/lecture/3').subscribe((response: Course) => {
        this.store.dispatch(new AuthActions.SetCourse(response));
        });
      },
      (error: Response) => {
        console.log('error', error);
      }
    );
  }

}
