import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faCcAmex, faCcDinersClub, faCcDiscover, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { Countries } from '@hor/data-access-shared/models/countries.model';
import { Provinces } from '@hor/data-access-shared/models/provinces.model';
import { Constant } from '@hor/data-access-shared/constants/constant';
import {
  PaypalItem,
  Item,
  OfficesModel,
  PaypalItemUnitAmount,
  ClientOrder,
  PaypalOrder,
  ShippingDetails,
  ShippingLocation,
  ApiShippingInfo,
  ApiShippingAddress,
  ApiOrderBreakdown,
  ApiOrderBreakdownDetail,
  ApiPurchaseItem,
  ApiPurchaseItemAmount,
  ApiOrderConfirmation,
} from '@hor/data-access-shared/models/thebracespace.model';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import moment from 'moment';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { HttpStatusActions } from '@hor/data-access-shared/states/http-status/http-status.action';
import { AuthActions } from '@hor/data-access-shared/states/auth/auth.action';
import { Router } from '@angular/router';

@Component({
  selector: 'hor-retainers',
  templateUrl: './retainers.component.html',
  styleUrls: ['./retainers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetainersComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  offices = new Array<OfficesModel>();

  paypalItems = new Array<PaypalItem>();
  items = new Array<Item>();
  submitted = false;
  total = 0;
  selectedIndex = -1;
  showPaypal = false;
  showSuccess = false;
  showError = false;
  showCancel = false;
  totalPaypal = '0.01';

  upperPrice = 250;
  lowerPrice = 250;
  bothPrice = 475;
  retainerCasePrice = 10;
  whiteningPrice = 50;
  deliveryPrice = 25;
  pickupPrice = 0;
  pickupLocations = ['Bedford', 'Dartmouth', 'Halifax', 'Sackville'];
  pickupAddresses = ['620 Nine Mile Drive', '599 Portland St, NS', '6155 North St', '405 Sackville Dr'];
  pickupAddresses2 = ['Suite 201', 'Suite B', 'Suite 409', 'Suite 103'];
  pickupCity = ['Bedford', 'Dartmouth', 'Halifax', 'Lower Sackville'];
  pickupProvince = ['NS', 'NS', 'NS', 'NS'];
  maxDate = new Date();
  @Input() orderConfirmation;
  @Input() showConfirmation = false;
  @Input() isSaving = false;

  accessToken: 'access_token$sandbox$6cxmd4rfh6jhz473$daf0530a67d71168a5a306be3271b962';

  isSameAddress = false;
  faCcAmex = faCcAmex;
  faCcMastercard = faCcMastercard;
  faCcVisa = faCcVisa;
  faCcDiscover = faCcDiscover;
  faCcDinersClub = faCcDinersClub;
  creditCardType: string;
  cardYears: number[];
  countries = Countries.values;
  provinces = Provinces.values;
  months = Constant.monthsShort;

  pickupPhones = ['(902) 455-7222', '(902) 435-3723', '(902) 706-7022', '(902) 252-7222'];
  retainerForm = new FormGroup({
    upperRetainer: new FormControl(0),
    lowerRetainer: new FormControl(0),
    bothRetainers: new FormControl(0),
    retainerCase: new FormControl(0),
    whitening: new FormControl(0),
    isNewMoldRequired: new FormControl(false),
    deliveryMethod: new FormControl('', Validators.required),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    country: new FormControl('CA', Validators.required),
    province: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    firstNamePatient: new FormControl('', Validators.required),
    lastNamePatient: new FormControl('', Validators.required),
    phoneNumberPatient: new FormControl('', Validators.required),
    emailPatient: new FormControl('', Validators.required),
    dobPatient: new FormControl('', Validators.required),
  });

  constructor(private store: Store, private http: HttpClient, private router: Router) {
    this.store.dispatch(new HttpStatusActions.SetErrorOverride(undefined));
  }

  ngOnInit(): void {
    this.initConfig();
    this.cardYears = this.loadCardYears();

    this.pickupLocations.map((x, i) => {
      const office = new OfficesModel();
      office.city = this.pickupCity[i];
      office.address = this.pickupAddresses[i];
      office.address2 = this.pickupAddresses2[i];
      office.name = this.pickupLocations[i];
      office.phone = this.pickupPhones[i];
      office.province = this.pickupProvince[i];
      this.offices.push(office);
    });
  }

  selectLocation = (index: number) => (this.selectedIndex = index);

  onSubmit() {
    this.submitted = true;

    if (this.retainerForm.status === 'VALID') {
      this.paypalItems = new Array<PaypalItem>();
      this.showPaypal = true;
      this.totalPaypal = this.getTotal().toString();
      this.items.map((x) => {
        const item = new PaypalItem();
        item.name = x.name;
        item.quantity = x.qty.toString();
        const unit = new PaypalItemUnitAmount();
        unit.value = x.unitCost.toString();
        item.unit_amount = unit;
        this.paypalItems.push(item);
      });

      if (this.retainerForm.get('deliveryMethod').value === 'ship') {
        const item = new PaypalItem();
        item.name = 'SHIPPING FEE';
        item.quantity = '1';
        const unit = new PaypalItemUnitAmount();
        unit.value = this.deliveryPrice.toString();
        item.unit_amount = unit;
        this.paypalItems.push(item);
      }
    }
  }

  sendOrderDetails(paypal: PaypalOrder) {

    this.store.dispatch(new AuthActions.IsSaving(true));
    const shippingOptions = [];
    const shippingLocation = new ApiShippingInfo();
    const orderBreakdown = new ApiOrderBreakdown();

    if (this.retainerForm.get('deliveryMethod').value === 'pickup') {
      const shippingAddress = new ApiShippingAddress();
      shippingAddress.address_line_1 = this.offices[this.selectedIndex].address;
      shippingAddress.address_line_2 = this.offices[this.selectedIndex].phone;
      shippingAddress.admin_area_1 = this.offices[this.selectedIndex].province;
      shippingAddress.admin_area_2 = `${this.offices[this.selectedIndex].city}`;
      shippingAddress.country_code = 'CA';
      shippingAddress.postal_code = '';

      shippingLocation.address = shippingAddress;
    }

    if (this.retainerForm.get('deliveryMethod').value === 'ship') {
      // if items are shipped then use this hardcoded object for now
      shippingOptions.push({
        id: 'SHIP0000001',
        label: '1 Day Priority',
        type: 'SHIPPING',
        amount: {
          currency_code: 'CAD',
          value: this.deliveryPrice.toString(),
        },
        selected: true,
      });

      //set shipping details for API
      const shippingAddress = new ApiShippingAddress();
      shippingAddress.address_line_1 = this.retainerForm.get('address').value;
      shippingAddress.address_line_2 = '';
      shippingAddress.admin_area_1 = this.retainerForm.get('province').value;
      shippingAddress.admin_area_2 = this.retainerForm.get('city').value;
      shippingAddress.country_code = 'CA';
      shippingAddress.postal_code = this.retainerForm.get('postalCode').value;

      shippingLocation.address = shippingAddress;

      const shippingLine = new ApiOrderBreakdownDetail();
      shippingLine.currency_code = 'CAD';
      shippingLine.value = this.deliveryPrice.toString();

      const taxLine = new ApiOrderBreakdownDetail();
      const tax = this.deliveryPrice * 0.15;
      taxLine.currency_code = 'CAD';
      taxLine.value = tax.toString();

      orderBreakdown.shipping = shippingLine;
      orderBreakdown.tax_total = taxLine;
    }

    const totalLine = new ApiOrderBreakdownDetail();
    totalLine.currency_code = 'CAD';
    totalLine.value = paypal.amount;
    orderBreakdown.item_total = totalLine;

    const purchaseItems = new Array<ApiPurchaseItem>();

    this.paypalItems.map((x) => {
      const item = new ApiPurchaseItem();
      const amount = new ApiPurchaseItemAmount();
      const tax = new ApiPurchaseItemAmount();

      if (!x.name.includes('SHIPPING')) {
        amount.value = x.unit_amount.value;
        amount.currency_code = 'CAD';

        tax.currency_code = 'CAD';
        tax.value = '0';
        item.category = 'PHYSICAL_GOODS';
        item.description = x.name;
        item.sku = x.name;
        item.name = x.name;
        item.quantity = x.quantity;
        item.unit_amount = amount;
        item.tax = tax;

        purchaseItems.push(item);
      }
    });

    const payload = {
      shipperFirstName: this.retainerForm.get('firstName').value,
      shipperLastName: this.retainerForm.get('lastName').value,
      firstName: this.retainerForm.get('firstNamePatient').value,
      lastName: this.retainerForm.get('lastNamePatient').value,
      dob: moment(this.retainerForm.get('dobPatient').value).format('MM/DD/YY'),
      phoneNumber: this.retainerForm.get('phoneNumberPatient').value,
      email: this.retainerForm.get('emailPatient').value,
      requiresNewMold: this.retainerForm.get('isNewMoldRequired').value,
      order: {
        purchase_units: [
          {
            reference_id: paypal.id,
            description: 'Online custom clear aligners',
            custom_id: 'CUST-Aligners',
            soft_descriptor: 'Aligners',
            amount: {
              currency_code: 'CAD',
              value: paypal.amount,
              breakdown: orderBreakdown,
            },
            items: purchaseItems,
            shipping: shippingLocation,
            options: shippingOptions,
          },
        ],
      },
    };

    return this.http.post('https://api.thebracespace.com:443/api/order', payload).subscribe((response: ApiOrderConfirmation) => {
      this.store.dispatch(new AuthActions.ConfirmOrder(response));
      this.store.dispatch(new AuthActions.IsSaving(false));
    });
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'CAD',
      clientId: 'AcbFyhSg_c-OChQJmXiLpIxD4qfAYPC4xF6ofLG8Pd5NrIONhsWPadUWd3V7LwxR393kYYRo3eXoK1uT',
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'CAD',
                value: this.totalPaypal,
                breakdown: {
                  item_total: {
                    currency_code: 'CAD',
                    value: this.totalPaypal,
                  },
                },
              },
              items: this.paypalItems,
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        //  console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details) => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        //  console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        const response = new PaypalOrder();
        response.createdTime = data.create_time;
        response.id = data.id;
        response.amount = this.totalPaypal; // data.purchase_units[0].amount.value;
        this.sendOrderDetails(response);
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        //  console.log('OnCancel', data, actions);
        this.showCancel = true;
      },
      onError: (err) => {
        //  console.log('OnError', err);
        this.showError = true;
      },
      onClick: (data, actions) => {
        //  console.log('onClick', data, actions);
        //  this.resetStatus();
      },
    };
  }

  loadCardYears(): number[] {
    let currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i < 10; i++) {
      years.push(currentYear);
      currentYear++;
    }
    return years;
  }

  getFullDate(date: Date) {
    return date ? moment(date).format('MMMM DD, YYYY') : '';
  }

  setDeliveryOption(method: string) {
    this.showPaypal = false;
    this.retainerForm.get('deliveryMethod').setValue(method);
    this.setShippingValidators(method);
  }

  checkValidDate() {
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1;

    if (currentYear == this.retainerForm.get('expirationYear').value) {
      if (this.retainerForm.get('expirationMonth').value != '') {
        if (this.retainerForm.get('expirationMonth').value < currentMonth) {
          alert('The expiration month and year selected are before the current month. Please select again');
          this.retainerForm.get('expirationMonth').setValue('');
          this.retainerForm.get('expirationYear').setValue('');
        }
      }
    }
  }

  isMoldRequired() {
    this.showPaypal = false;
  }

  onCreditCardTypeChanged = (type: string) => {
    this.creditCardType = type;
    this.retainerForm.get('cardType').setValue(type);
  };

  setShippingValidators(method: string) {
    const validator = method === 'ship' ? Validators.required : undefined;
    this.retainerForm.get('firstName').setValidators(validator);
    this.retainerForm.get('firstName').updateValueAndValidity();
    this.retainerForm.get('lastName').setValidators(validator);
    this.retainerForm.get('lastName').updateValueAndValidity();
    this.retainerForm.get('address').setValidators(validator);
    this.retainerForm.get('address').updateValueAndValidity();
    this.retainerForm.get('phoneNumber').setValidators(validator);
    this.retainerForm.get('phoneNumber').updateValueAndValidity();
    this.retainerForm.get('email').setValidators(validator);
    this.retainerForm.get('email').updateValueAndValidity();
    this.retainerForm.get('city').setValidators(validator);
    this.retainerForm.get('city').updateValueAndValidity();
    this.retainerForm.get('country').setValidators(validator);
    this.retainerForm.get('country').updateValueAndValidity();
    this.retainerForm.get('province').setValidators(validator);
    this.retainerForm.get('province').updateValueAndValidity();
    this.retainerForm.get('postalCode').setValidators(validator);
    this.retainerForm.get('postalCode').updateValueAndValidity();
  }

  updateItems() {
    this.items = new Array<Item>();
    this.showPaypal = false;

    if (this.retainerForm.get('upperRetainer').value > 0) {
      const newItem = new Item();
      newItem.qty = this.retainerForm.get('upperRetainer').value;
      newItem.name = `Upper Retainer${newItem.qty > 1 ? 's' : ''}`;
      newItem.unitCost = this.upperPrice;
      newItem.cost = this.retainerForm.get('upperRetainer').value * this.upperPrice;
      this.items.push(newItem);
    }

    if (this.retainerForm.get('lowerRetainer').value > 0) {
      const newItem = new Item();
      newItem.qty = this.retainerForm.get('lowerRetainer').value;
      newItem.unitCost = this.lowerPrice;
      newItem.name = `Lower Retainer${newItem.qty > 1 ? 's' : ''}`;
      newItem.cost = this.retainerForm.get('lowerRetainer').value * this.lowerPrice;
      this.items.push(newItem);
    }

    if (this.retainerForm.get('bothRetainers').value > 0) {
      const newItem = new Item();
      newItem.qty = this.retainerForm.get('bothRetainers').value;
      newItem.unitCost = this.bothPrice;
      newItem.name = `Upper & Lower Retainer${newItem.qty > 1 ? 's' : ''}`;
      newItem.cost = this.retainerForm.get('bothRetainers').value * this.bothPrice;
      this.items.push(newItem);
    }

    if (this.retainerForm.get('retainerCase').value > 0) {
      const newItem = new Item();
      newItem.qty = this.retainerForm.get('retainerCase').value;
      newItem.name = `Retainer Case${newItem.qty > 1 ? 's' : ''}`;
      newItem.unitCost = this.retainerCasePrice;
      newItem.cost = this.retainerForm.get('retainerCase').value * this.retainerCasePrice;
      this.items.push(newItem);
    }

    if (this.retainerForm.get('whitening').value > 0) {
      const newItem = new Item();
      newItem.qty = this.retainerForm.get('whitening').value;
      newItem.name = `Whitening Tube${newItem.qty > 1 ? 's' : ''}`;
      newItem.unitCost = this.whiteningPrice;
      newItem.cost = this.retainerForm.get('whitening').value * this.whiteningPrice;
      this.items.push(newItem);
    }

    this.onTotal();
    this.totalPaypal = this.getTotal().toString();
  }

  onTotal = () =>
    (this.total =
      this.retainerForm.get('retainerCase').value * this.retainerCasePrice +
      this.retainerForm.get('whitening').value * this.whiteningPrice +
      this.retainerForm.get('upperRetainer').value * this.upperPrice +
      this.retainerForm.get('lowerRetainer').value * this.lowerPrice +
      this.retainerForm.get('bothRetainers').value * this.bothPrice);

  getTotal = () => this.getSubTotal(); //leave room here for tax in the future if need be it

  getSubTotal = () => (this.retainerForm.get('deliveryMethod').value === 'ship' ? this.total + this.deliveryPrice : this.total);

  resetPage() {
    window.location.href = `https://store.thebracespace.com/retainers`;
  }
}
