import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Attendee, Course, PaypalItem, PaypalItemUnitAmount, PaypalOrder, RegistrationDetails } from '@hor/data-access-shared/models/thebracespace.model';
import { AuthActions } from '@hor/data-access-shared/states/auth/auth.action';
import { HttpStatusActions } from '@hor/data-access-shared/states/http-status/http-status.action';
import { Store } from '@ngxs/store';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'hor-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationComponent implements OnInit {
  paypalItems = new Array<PaypalItem>();
  @Input() confirmedAttendees = new Array<Attendee>();
  @Input() course = new Course();
  @Input() isRegistered: boolean;
  isShortSeats = false;
  public payPalConfig?: IPayPalConfig;
  attendeeFormsProp = 'attendeeBookings';
  formArray = null;
  submitted = false;
  staffPrice = this.course.staffPrice;
  doctorPrice = this.course.doctorPrice;
  showPaypal = false;
  isSoldOut = false;
  baseUrl = 'https://api-m.sandbox.paypal.com';
  total = '0';

  displayedColumns: string[] = ['firstName', 'lastName', 'lectureAttendeeType', 'phoneNumber', 'email', 'cost'];

  registrationForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    attendeeType: new FormControl('', Validators.required),
    cost: new FormControl(0, Validators.required),
  });

  showSuccess = false;
  showError = false;
  showCancel = false;

  constructor(private store: Store, private http: HttpClient) {
    this.store.dispatch(new HttpStatusActions.SetErrorOverride(undefined));
  }

  ngOnInit(): void {
    this.initConfig();
    this.registrationForm.addControl(this.attendeeFormsProp, new FormArray([]));

    this.formArray = this.getRegistrationForms();
    this.formArray.push(this.firstFormGroup());
  }

  private initConfig(): void {
    //live live clientId: ARKA9jrdL-E84glu0iQr_Lz6OMv-6tYKX4SHWs8c4JftCVGC2ohykKxBVFkdnnqsGdJOPQ487DEw64ZK
    // live clientId: AZ_YNq4Gmt5zuat0zfzVaA6CzqulF7f3u5CDtScOURa1OR-4IK2bksjmC_PBTUdE4wqJ0xfQd5s3L6pk
    //sandbox clientId:AaDmAu1ikhW7r_2jySnq65gBowUJb7d5ZUC2ecdWDt7fKz_Pk8tUU-uX24blZvWP1CTuAy_Adr1ccooG
    this.payPalConfig = {
      currency: 'CAD',
      clientId: 'ARKA9jrdL-E84glu0iQr_Lz6OMv-6tYKX4SHWs8c4JftCVGC2ohykKxBVFkdnnqsGdJOPQ487DEw64ZK',
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'CAD',
                value: this.total,
                breakdown: {
                  item_total: {
                    currency_code: 'CAD',
                    value: this.total,
                  },
                },
              },
              items: this.paypalItems,
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details) => {
          //     console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        //   console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        const response = new PaypalOrder();
        response.createdTime = data.create_time;
        response.id = data.id;
        response.amount = this.total; // data.purchase_units[0].amount.value;
        this.sendOrderDetails(response);
      },
      onCancel: (data, actions) => {
        //  console.log('OnCancel', data, actions);
        this.showCancel = true;
      },
      onError: (err) => {
        console.log('OnError', err);
        this.showError = true;
      },
      onClick: (data, actions) => {
        //  console.log('onClick', data, actions);
        //  this.resetStatus();
      },
    };
  }

  checkIsSoldOut() {
    let isSoldOut = undefined;
    this.http.get('https://api.thebracespace.com/api/lecture/3').subscribe((response: Course) => {
      isSoldOut = this.getRegistrationFormsControls().length <= response.seatsRemaining ? false : true;
      this.store.dispatch(new AuthActions.SetCourse(response));
      this.onSubmit(isSoldOut);
    });
  }

  onSubmit(isSoldOut: boolean) {
    this.submitted = true;
    if (this.registrationForm.status === 'VALID') {
      if (isSoldOut) {
        this.isShortSeats = isSoldOut;
      } else {
        this.paypalItems = new Array<PaypalItem>();
        this.total = this.getTotal().toString();
        const docs = this.getRegistrationFormsControls().reduce((sum, x) => (sum += x.get('attendeeType').value === 'doctor' ? 1 : 0), 0);
        const staff = this.getRegistrationFormsControls().reduce((sum, x) => (sum += x.get('attendeeType').value === 'staff' ? 1 : 0), 0);
        if (docs > 0) {
          const item = new PaypalItem();
          item.name = docs === 1 ? 'doctor' : 'doctors';
          item.quantity = docs.toString();
          const unit = new PaypalItemUnitAmount();
          unit.value = this.course.doctorPrice.toString();
          item.unit_amount = unit;
          this.paypalItems.push(item);
        }
        if (staff > 0) {
          const item = new PaypalItem();
          item.name = 'staff';
          item.quantity = staff.toString();
          const unit = new PaypalItemUnitAmount();
          unit.value = this.course.staffPrice.toString();
          item.unit_amount = unit;
          this.paypalItems.push(item);
        }
        this.showPaypal = true;
      }
    }
  }

  sendOrderDetails(paypal: PaypalOrder) {
    const attendees = new Array<Attendee>();
    this.getRegistrationFormsControls().map((x, index) => {
      const attendee = new Attendee();
      attendee.cost = x.get('cost').value.toString();
      attendee.email = x.get('email').value;
      attendee.firstName = x.get('firstName').value;
      attendee.lastName = x.get('lastName').value;
      attendee.phoneNumber = x.get('phoneNumber').value;
      attendee.lectureAttendeeType = x.get('attendeeType').value;
      attendee.ordinal = attendees.push(attendee);
    });

    const registrationDetails = new RegistrationDetails();
    registrationDetails.paypalId = paypal.id;
    registrationDetails.totalAmount = paypal.amount;
    registrationDetails.attendees = attendees;
    registrationDetails.paypalCreationTime = paypal.createdTime;

    const payload = {
      numAttendees: attendees.length,
      lectureId: this.course.id,
      paypalTransactionId: registrationDetails.paypalId,
      paypalReceiptId: registrationDetails.paypalId,
      paypalUrl: this.course.paypalUrl,
      lectureAttendeeList: attendees,
    };

    return this.http.post('https://api.thebracespace.com:443/api/lectureregistration', payload).subscribe((response: any) => {
      this.store.dispatch(new AuthActions.SetAttendees(attendees));
        });
  }

  addPerson() {
    this.registrationForm.addControl(this.attendeeFormsProp, new FormArray([]));

    this.formArray = this.getRegistrationForms();
    this.formArray.push(this.newFormGroup());
    this.showPaypal = false;
    if (this.getRegistrationFormsControls().length >= this.course.seatsRemaining) {
      this.isSoldOut = true;
    }
  }

  removePerson(index: number) {
    this.formArray.removeAt(index);
    if (this.getRegistrationFormsControls().length < this.course.seatsRemaining) {
      this.isSoldOut = false;
    }
  }

  setCost(index: number, type: string) {
    const cost = type === 'staff' ? this.course.staffPrice : this.course.doctorPrice;
    this.formArray.controls[index].controls.cost.setValue(cost);
    this.total = this.getTotal();
  }

  getPrice = (index: number) => (this.formArray.controls[index].controls.attendeeType.value === 'staff' ? this.staffPrice : this.doctorPrice);

  newFormGroup = () => {
    const firstNameControl = new FormControl('');
    firstNameControl.setValue('');
    firstNameControl.setValidators(Validators.required);
    firstNameControl.updateValueAndValidity();

    const lastNameControl = new FormControl('');
    lastNameControl.setValue('');
    lastNameControl.setValidators(Validators.required);
    lastNameControl.updateValueAndValidity();

    const attendeeTypeControl = new FormControl('');
    attendeeTypeControl.setValue('');
    attendeeTypeControl.setValidators(Validators.required);
    attendeeTypeControl.updateValueAndValidity();

    const costControl = new FormControl('');
    costControl.setValue(0);
    costControl.setValidators(Validators.required);
    costControl.updateValueAndValidity();

    const emailControl = new FormControl('');
    emailControl.setValue('');
    emailControl.setValidators(Validators.required);
    emailControl.updateValueAndValidity();

    const phoneNumberControl = new FormControl('');
    phoneNumberControl.setValue('');
    phoneNumberControl.setValidators(undefined);
    phoneNumberControl.updateValueAndValidity();

    return new FormGroup({
      firstName: firstNameControl,
      lastName: lastNameControl,
      attendeeType: attendeeTypeControl,
      phoneNumber: phoneNumberControl,
      email: emailControl,
      cost: costControl,
    });
  };

  firstFormGroup = () => {
    return new FormGroup({
      firstName: this.registrationForm.get('firstName'),
      lastName: this.registrationForm.get('lastName'),
      attendeeType: this.registrationForm.get('attendeeType'),
      phoneNumber: this.registrationForm.get('phoneNumber'),
      email: this.registrationForm.get('email'),
      cost: this.registrationForm.get('cost'),
    });
  };

  getRegistrationForms = () => <FormArray>this.registrationForm.get(this.attendeeFormsProp);

  getRegistrationFormsControls = () => this.getRegistrationForms().controls;

  getTotal = () => this.getRegistrationFormsControls().reduce((sum, x) => (sum += x.get('cost').value), 0);

  getPurchaseTotal = () => this.confirmedAttendees.reduce((sum, x) => (sum += +x.cost), 0);
}
