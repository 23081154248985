import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APP_CONFIG } from '@hor/app-config';
import { Environment } from '@hor/data-access-shared/interfaces/environment';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private appConfig: Environment) {}
  login(): any {
    return this.http.post('https://api.thebracespace.com/api/v1/auth/login', { email: 'tmac@razorside.com', username: 'tmachern', password: 'hello' });
  }
  register(data) {
    return this.http.post(this.appConfig.secureApiUrl + 'api/register', data);
  }
  forgot(data) {
    return this.http.post(
      this.appConfig.secureApiUrl + 'api/account/forgot_password/init',
      {},
      {
        params: new HttpParams().set('email', data),
      }
    );
  }
  checkEmail(data) {
    return this.http.post(this.appConfig.secureApiUrl + 'api/email-verification', { email: data });
  }
}
