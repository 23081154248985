import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { HttpStatusActions } from './http-status.action';

export interface HttpErrorModel {
  date: Date;
  message: string;
}

export interface HttpStatusModel {
  error: HttpErrorModel;
  errorOverride: HttpErrorModel;
  loading: boolean;
}

@State<HttpStatusModel>({
  name: 'httpStatus',
  defaults: {
    error: undefined,
    errorOverride: undefined,
    loading: false,
  },
})
@Injectable()
export class HttpStatusState {
  @Selector()
  static getError({ error, errorOverride }: HttpStatusModel) {
    return error ? errorOverride ?? error : '';
  }

  @Selector()
  static getLoading({ loading }: HttpStatusModel) {
    return loading;
  }

  @Action(HttpStatusActions.SetError)
  setError(
    { patchState }: StateContext<HttpStatusModel>,
    { error }: HttpStatusActions.SetError
  ) {
    patchState({
      error: {
        date: new Date(),
        message: error,
      },
    });
  }

  @Action(HttpStatusActions.SetErrorOverride)
  setErrorOverride(
    { patchState }: StateContext<HttpStatusModel>,
    { error }: HttpStatusActions.SetErrorOverride
  ) {
    patchState({
      errorOverride: {
        date: new Date(),
        message: error,
      },
    });
  }

  @Action(HttpStatusActions.ClearErrors)
  clearErrors({ patchState }: StateContext<HttpStatusModel>) {
    patchState({
      error: undefined,
      errorOverride: undefined,
    });
  }

  @Action(HttpStatusActions.SetLoading)
  setLoading(
    { patchState }: StateContext<HttpStatusModel>,
    { loading }: HttpStatusActions.SetLoading
  ) {
    patchState({
      loading: loading,
    });
  }
}
