<div fxLayout="column">
  <form [formGroup]="retainerForm" (ngSubmit)="onSubmit()" *ngIf="!showConfirmation && !isSaving">
    <div class="section-container" fxLayout="column">
      <h3>Patient Information</h3>

      <div fxLayout="column">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >First Name
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('firstNamePatient').status === 'INVALID'">required</p></mat-label
            >
            <input matInput formControlName="firstNamePatient" />
          </mat-form-field>
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Last Name
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('lastNamePatient').status === 'INVALID'">required</p></mat-label
            >
            <input matInput formControlName="lastNamePatient" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Phone Number
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('phoneNumberPatient').status === 'INVALID'">required</p></mat-label
            >
            <input matInput formControlName="phoneNumberPatient" mask="(000) 000-0000" placeholder="(000) 000-0000" />
          </mat-form-field>
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto" (click)="picker.open()">
            <mat-label fxLayout="row"
              >Date of Birth
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('dobPatient').status === 'INVALID'">required</p></mat-label
            >
            <input matInput [matDatepicker]="picker" [readonly]="true" [max]="maxDate" placeholder="Choose a date" formControlName="dobPatient" style="cursor: pointer;" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Email
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('emailPatient').status === 'INVALID'">
                {{ retainerForm.get('emailPatient').value != '' ? 'Please enter a valid email' : 'required' }}
              </p></mat-label
            >
            <input matInput formControlName="emailPatient" type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$" />
          </mat-form-field>
          <mat-checkbox style="padding-top: 25px; padding-left: 15px;" fxFlex="1 1 auto" fxLayoutAlign="start center" (click)="isMoldRequired()" formControlName="isNewMoldRequired"
            >Require new mold?</mat-checkbox
          >
        </div>
      </div>

      <div></div>
    </div>
    <div class="section-divider"></div>
    <div class="section-container">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start">
        <h3>Clear Retainers</h3>
        <h4 *ngIf="submitted && total <= 0" class="required">*Please select something for your order</h4>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="start center" class="retainers-container">
        <div fxLayout="column" fxLayoutAlign="center center">
          <h3 fxLayout.lt-md="row">Upper & Lower: {{ bothPrice | currency }}</h3>
          <div class="retainer-image"><img src="assets/img/upper_lower.jpeg" /></div>
          <hor-incrementor [parentForm]="retainerForm" [control]="retainerForm.get('bothRetainers')" [max]="4" [amount]="0" min="0" (updateTotal)="updateItems()"></hor-incrementor>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <h3 fxLayout.lt-md="row">Upper: {{ upperPrice | currency }}</h3>
          <div class="retainer-image"><img src="assets/img/upper.jpeg" /></div>
          <hor-incrementor [parentForm]="retainerForm" [control]="retainerForm.get('upperRetainer')" [max]="4" [amount]="0" min="0" (updateTotal)="updateItems()"></hor-incrementor>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <h3 fxLayout.lt-md="row">Lower: {{ lowerPrice | currency }}</h3>
          <div class="retainer-image"><img src="assets/img/lower.jpeg" /></div>
          <hor-incrementor [parentForm]="retainerForm" [control]="retainerForm.get('lowerRetainer')" [max]="4" [amount]="0" min="0" (updateTotal)="updateItems()"></hor-incrementor>
        </div>
      </div>
      <div class="section-divider"></div>
      <div class="section-container">
        <h3>Add-Ons</h3>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="start center" class="retainers-container">
          <div fxLayout="column" fxLayoutAlign="center center">
            <h3 fxLayout.lt-md="row">Retainer Case: {{ retainerCasePrice | currency }}</h3>
            <div class="retainer-image"><img src="assets/img/retainer-case.jpeg" /></div>
            <hor-incrementor
              [parentForm]="retainerForm"
              [control]="retainerForm.get('retainerCase')"
              [max]="4"
              [amount]="0"
              min="0"
              (updateTotal)="updateItems()"
            ></hor-incrementor>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <h3 fxLayout.lt-md="row">Whitening {{ whiteningPrice | currency }}</h3>
            <div class="retainer-image"><img src="assets/img/whitening.jpeg" /></div>
            <hor-incrementor [parentForm]="retainerForm" [control]="retainerForm.get('whitening')" [max]="4" [amount]="0" min="0" (updateTotal)="updateItems()"></hor-incrementor>
          </div>
        </div>

        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <h4>Order Items Total</h4>
        <div class="retainer-total">{{ total | currency }}</div>
      </div> -->
      </div>
    </div>
    <div class="section-divider"></div>
    <div class="section-container">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start">
        <h3>Delivery Options</h3>
        <h4 *ngIf="submitted && retainerForm.get('deliveryMethod').value === ''" class="required">*Please select a delivery option</h4>
      </div>
      <mat-radio-group fxLayout="row" fxLayoutGap="30px" formControlName="deliveryMethod">
        <mat-radio-button value="pickup" (click)="setDeliveryOption('pickup')">Pickup {{ pickupPrice | currency }} </mat-radio-button>
        <mat-radio-button value="ship" (click)="setDeliveryOption('ship')">Delivery {{ deliveryPrice | currency }}</mat-radio-button>
      </mat-radio-group>
      <br *ngIf="retainerForm.get('deliveryMethod').value === ''" />
      <div class="locations-container" *ngIf="retainerForm.get('deliveryMethod').value === 'pickup'" fxLayout="column">
        <h4>*Please select which office you would like to pickup your order from</h4>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center">
          <h4>Office Locations</h4>
          <h4 *ngIf="submitted && retainerForm.get('deliveryMethod').value === 'pickup' && selectedIndex < 0" class="required">*Please select a pickup location</h4>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxFlex="1 1 auto" fxLayoutAlign="center center">
          <div
            *ngFor="let o of offices; index as index"
            fxLayout="column"
            [ngClass]="{ 'office-row': index % 2 != 0, 'office-alternate-row': index % 2 === 0, 'selected-office': selectedIndex === index }"
            fxLayoutAlign="center center"
          >
            <div class="office-name">{{ o.name }}</div>
            <div class="office-address">{{ o.address }}</div>
            <div class="office-address">{{ o.address2 }}</div>
            <div class="office-address">{{ o.city }}, {{ o.province }}</div>
            <div class="office-address">{{ o.phone }}</div>
            <button type="button" mat-raised-button class="bracespace-select-button" (click)="selectLocation(index)">{{ selectedIndex === index ? 'SELECTED' : 'SELECT' }}</button>
          </div>
        </div>
      </div>
      <div *ngIf="retainerForm.get('deliveryMethod').value === 'ship'" fxLayout="column" class="shipping-container">
        <div fxLayout="row">
          <h4>Shipping Information</h4>
          <div class="canada-only">(*Canada Only)</div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >First Name
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('firstName').status === 'INVALID'">required</p></mat-label
            >
            <input matInput formControlName="firstName" />
          </mat-form-field>
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Last Name
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('lastName').status === 'INVALID'">required</p></mat-label
            >
            <input matInput formControlName="lastName" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Phone Number
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('phoneNumber').status === 'INVALID'">
                {{ retainerForm.get('phoneNumber').value != '' ? 'Please enter a valid phone number' : 'required' }}
              </p></mat-label
            >
            <input matInput formControlName="phoneNumber" mask="(000) 000-0000" placeholder="(000) 000-0000" />
          </mat-form-field>
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Email
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('email').status === 'INVALID'">
                {{ retainerForm.get('email').value != '' ? 'Please enter a valid email' : 'required' }}
              </p></mat-label
            >
            <input matInput formControlName="email" type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$" />
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Address
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('address').status === 'INVALID'">required</p></mat-label
            >
            <input matInput formControlName="address" />
          </mat-form-field>
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >City
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('city').status === 'INVALID'">required</p></mat-label
            >
            <input matInput formControlName="city" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Province
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('province').status === 'INVALID'">required</p></mat-label
            >
            <mat-select formControlName="province">
              <mat-option value="">-- Select a Province --</mat-option>
              <mat-option *ngFor="let province of provinces" [value]="province.code">
                {{ province.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Country
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('country').status === 'INVALID'">required</p></mat-label
            >
            <mat-select formControlName="country">
              <mat-option value="">-- Select a Country --</mat-option>
              <mat-option *ngFor="let country of countries" [value]="country.code">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

          <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 auto">
            <mat-label fxLayout="row"
              >Postal Code
              <p class="required">*</p>
              <p class="required" *ngIf="submitted && retainerForm.get('postalCode').status === 'INVALID'">
                {{ retainerForm.get('postalCode').value != '' ? 'Please enter a valid Postal Code' : 'required' }}
              </p></mat-label
            >
            <input matInput formControlName="postalCode" type="text" maxlength="6" mask="S0S0S0" pattern="[A-Z]+[0-9]+[A-Z]+[0-9]+[A-Z]+[0-9]" />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="order-summary" [ngClass.lt-md]="'order-summary-mobile'" fxLayout="column">
      <h3 class="summary-title">Order Summary</h3>
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex="1 1 auto" fxLayoutAlign="center start" class="summary-detail-container">
        <div fxLayout="column" fxFlex="1 1 auto" [ngClass.lt-md]="'summary-title-mobile'">
          <div fxLayout="column" fxFlex="1 1 auto" class="summary-title-mobile">
            <h5>Items Detail</h5>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between" class="summary-item-container" fxFlex="1 1 auto">
            <div *ngFor="let i of items; index as index" fxLayout="row" fxLayoutGap="15px" class="order-items" fxLayoutAlign="space-between center">
              <div>{{ i.qty }} - {{ i.name }}</div>
              <div>{{ i.cost | currency }}</div>
            </div>
            <div *ngIf="items.length < 1" fxLayoutGap="15px" class="order-items">
              <div>No Items Ordered</div>
            </div>
            <div *ngIf="retainerForm.get('deliveryMethod').value === 'ship'" fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="space-between" class="order-items">
              <div>1 - Shipping Charge</div>
              <div>{{ deliveryPrice | currency }}</div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="1 1 auto">
          <div fxLayout="column" fxFlex="1 1 auto" class="summary-title-mobile">
            <h5>Total Cost</h5>
          </div>
          <!-- <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" [ngClass.gt-md]="'summary-item-container'">
            <h3>Purchase Sub-Total</h3>
            <div class="retainer-total">{{ getSubTotal() | currency }}</div>
          </div> -->
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" [ngClass.gt-md]="'summary-item-container'">
            <h3>Purchase Total</h3>
            <div class="retainer-total">{{ getTotal() | currency }}</div>
          </div>
        </div>
      </div>
      <div fxLayoutAlign="start start" fxLayout="column">
        <button mat-raised-button class="bracespace-button">
          PAY FOR ORDER
        </button>
        <div style="height: 40px;">
          <mat-label><p class="required" *ngIf="submitted && (retainerForm.status === 'INVALID' || total <= 0)">Please correct errors above</p></mat-label>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" [fxShow]="showPaypal && retainerForm.status === 'VALID' && total > 0">
        <ngx-paypal [config]="payPalConfig"></ngx-paypal>
      </div>
    </div>
  </form>
  <div *ngIf="showConfirmation" fxLayout="column">
    
    <h2>Order Details</h2>
    <h4>Your Order Has Been Confirmed!</h4>    
    <br>
    <h3>Patient Information</h3>
    <br>
    <div fxLayout="row">
      <h6>Name:</h6>
      <div>{{ orderConfirmation.patientInformation.firstName }} {{ orderConfirmation.patientInformation.lastName }}</div>
    </div>
    <div fxLayout="row">
      <h6>Date of Birth:</h6>
      <div>{{ getFullDate(orderConfirmation.patientInformation.dateOfBirth) }}</div>
    </div>
    <div fxLayout="row">
      <h6>Email:</h6>
      <div>{{ orderConfirmation.patientInformation.emailAddress }}</div>
    </div>
    <div fxLayout="row">
      <h6>Phone:</h6>
      <div>{{ orderConfirmation.patientInformation.phoneNumber }}</div>
    </div>
    <div fxLayout="row">
      <h6>New Mold?:</h6>
      <div>{{ orderConfirmation.patientInformation.requiresNewMold ? 'Yes': 'No' }}</div>
    </div>
    <br>
    <h3>Items Ordered</h3>
    <br>
    <div *ngIf="orderConfirmation.orderItemList.length > 0" fxLayout="row">
      <div class="order-item-header order-item-price">Unit Price</div>
      <div class="order-item-header order-item-qty">Qty</div>      
      <div class="order-item-header">Description</div>
      
    </div>
    <div *ngFor="let item of orderConfirmation.orderItemList">
      <div fxLayout="row">
        <div class="order-item-price">{{ item.unitAmount | currency }}</div>
        <div class="order-item-qty">{{ item.quantity }}</div>        
        <div >{{ item.description }}</div>        
      </div>
    </div>
    <div *ngIf="orderConfirmation.shipperFirstName && orderConfirmation.shipperFirstName.length > 0">
      <br>
      <h3>Shipping Information</h3>
      <br>
      <div fxLayout="row">
        <h6>Name:</h6>
        <div>{{ orderConfirmation.shipperFirstName }} {{ orderConfirmation.shipperLastName }}</div>
      </div>
      <div fxLayout="row">
        <h6>Address:</h6>
        <div>{{ orderConfirmation.shippingDetails.addressLine1 }}</div>
      </div>
      <div fxLayout="row">
        <h6></h6>
        <div>{{ orderConfirmation.shippingDetails.adminArea2 }}, {{ orderConfirmation.shippingDetails.adminArea1 }}</div>
      </div>
      <div fxLayout="row">
        <h6></h6>
        <div>{{ orderConfirmation.shippingDetails.postalCode }}</div>
      </div>
      <div fxLayout="row">
        <h6>Shipping Cost:</h6>
        <div>{{ deliveryPrice | currency }}</div>
      </div>
    </div>
    <div *ngIf="orderConfirmation.shipperFirstName.length < 1">
      <br>
      <h3>Pickup Information</h3>
      <br>
      <div fxLayout="row">
        <h6>Office:</h6>
        <div>{{ orderConfirmation.shippingDetails.adminArea2 }} Office</div>
      </div>
      <div fxLayout="row">
        <h6>Address:</h6>
        <div>{{ orderConfirmation.shippingDetails.addressLine1 }}</div>
      </div>
      <div fxLayout="row">
        <h6></h6>
        <div>{{ orderConfirmation.shippingDetails.adminArea2 }}, {{ orderConfirmation.shippingDetails.adminArea1 }}</div>
      </div>      
      <div fxLayout="row">
        <h6>Phone:</h6>
        <div>{{ orderConfirmation.shippingDetails.addressLine2 }}</div>
      </div>      
    </div>
    <br>
    <h3>Purchase Amount</h3>
    <br>
    <div fxLayout="row">
      <h6>Sub-total:</h6>
      <div>{{ orderConfirmation.totalBeforeTax | currency }}</div>
    </div>
    <div fxLayout="row">
      <h6>Tax:</h6>
      <div>{{ orderConfirmation.totalTax | currency }}</div>
    </div>
    <div fxLayout="row">
      <h6>Total:</h6>
      <div>{{ orderConfirmation.totalAfterTax | currency }}</div>
    </div>
    <br>
    <div fxLayoutAlign="start start" fxLayout="column">
      <button mat-raised-button class="bracespace-button" type="button" (click)="resetPage()">
        Place another order
      </button>
      <div style="height: 40px;">
        <mat-label><p class="required" *ngIf="submitted && (retainerForm.status === 'INVALID' || total <= 0)">Please correct errors above</p></mat-label>
      </div>
    </div>
  </div>
</div>
