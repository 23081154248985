import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GlobalEventsActions } from './global-events.action';
import { AlertType } from '../../models/alert.model';
import { Constant } from '../../constants/constant';
import { ChatMember } from '@hor/data-access-shared/interfaces/chatService';

export class LoadingSignalSetting {
  color = 'primary';
  mode = 'indeterminate';
  diameter = 48;
  value = 10;
  strokeWidth = 10;
}

export class MessageSetting {
  title = '';
  type: AlertType = AlertType.Success;
  msgShort = '';
  msgLong: string[]= [];
  timeout = Constant.defaultSnackbarDuration;
}

export interface MessageToShow {
  isShowMessage: boolean;
  message: MessageSetting;
}

export interface VideoCallSetting {
  fakeChatId: string;
  videoChatId: string;
  originator: string;
}

export interface GlobalSignalModel {
  loadingSetting: LoadingSignalSetting;
  showLoading: boolean;
  messageToShow: MessageToShow;
  videoCallSetting: VideoCallSetting;
}

@State<GlobalSignalModel>({
  name: 'globalEvents',
  defaults: {
    loadingSetting: new LoadingSignalSetting(),
    showLoading: false,
    messageToShow: null,
    videoCallSetting: null
  },
})
@Injectable()
export class GlobalEventsState {
  @Selector()
  static isBusyLoading({ showLoading }: GlobalSignalModel) {
    return showLoading;
  }

  @Selector()
  static getLoadingSetting({ loadingSetting }: GlobalSignalModel): LoadingSignalSetting {
    return loadingSetting;
  }

  @Selector()
  static messagesToShow({ messageToShow }: GlobalSignalModel) {
    return messageToShow;
  }

  @Selector()
  static videoCallSetting({ videoCallSetting }: GlobalSignalModel) {
    return videoCallSetting;
  }

  @Action(GlobalEventsActions.ShowBusyLoading)
  show(
    { patchState, getState }: StateContext<GlobalSignalModel>,
    { setting }: GlobalEventsActions.ShowBusyLoading
  ) {
    const { loadingSetting } = getState();

    patchState({
      loadingSetting: { ...loadingSetting, ...setting },
      showLoading: true,
    });
  }

  @Action(GlobalEventsActions.HideBusyLoading)
  hide(
    { patchState }: StateContext<GlobalSignalModel>
  ) {
    patchState({
      loadingSetting: new LoadingSignalSetting(),
      showLoading: false,
    });
  }

  @Action(GlobalEventsActions.ShowMessage)
  showMessage(
    { patchState, getState }: StateContext<GlobalSignalModel>,
    { setting }: GlobalEventsActions.ShowMessage
  ) {
    const defaultSetting = new MessageSetting();

    patchState({
      messageToShow: {
        isShowMessage: true,
        message: { ...defaultSetting, ...setting }
      }
    });
  }

  @Action(GlobalEventsActions.HideMessage)
  hideMessage(
    { patchState }: StateContext<GlobalSignalModel>
  ) {
    patchState({
      messageToShow: {
        isShowMessage: false,
        message: null
      }
    });
  }

  @Action(GlobalEventsActions.ShowVideoChatInvitation)
  showVideoChatInvitation(
    { patchState }: StateContext<GlobalSignalModel>,
    { fakeChatId, videoChatId, originator }: GlobalEventsActions.ShowVideoChatInvitation
  ) {
    return patchState({
      videoCallSetting: {
        fakeChatId: fakeChatId,
        videoChatId: videoChatId,
        originator: originator
      }
    });
  }

  @Action(GlobalEventsActions.HideVideoChatInvitation)
  hideVideoChatInvitation(
    { patchState }: StateContext<GlobalSignalModel>
  ) {
    patchState({
      videoCallSetting: null
    });
  }

}
