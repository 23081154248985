import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  HttpErrorModel,
  HttpStatusState,
} from '@hor/data-access-shared/states/http-status/http-status.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'hor-http-status-container',
  templateUrl: './http-status-container.component.html',
  styleUrls: ['./http-status-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HttpStatusContainerComponent {
  @Select(HttpStatusState.getError)
  error$: Observable<HttpErrorModel>;
}
