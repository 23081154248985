import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Select } from '@ngxs/store';
import { GlobalEventsState, MessageSetting, LoadingSignalSetting, MessageToShow, VideoCallSetting } from '@hor/data-access-shared/states/global-events/global-events.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'hor-global-events-container',
  templateUrl: './global-events-container.component.html',
  styleUrls: ['./global-events-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalEventsContainerComponent implements OnInit {

  @Select(GlobalEventsState.messagesToShow) messagesToShow$: Observable<MessageToShow>;
  @Select(GlobalEventsState.isBusyLoading) isBusyLoading$: Observable<boolean>;
  @Select(GlobalEventsState.getLoadingSetting) busyLoadingSetting$: Observable<LoadingSignalSetting>;
  @Select(GlobalEventsState.videoCallSetting) videoCallSetting$: Observable<VideoCallSetting>;

  constructor() { }

  ngOnInit(): void {
  }

}
