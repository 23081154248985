import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_CONFIG } from '@hor/app-config';
import { CustomRouterStateSerializer } from '@hor/data-access-shared/states/router-state-serializer';
import { UiSharedModule } from '@hor/ui-shared';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import {
  NgxsRouterPluginModule,
  RouterStateSerializer,
} from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { ChatService } from '@hor/data-access-shared/interfaces/chatService';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase),
    
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),

    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.LocalStorage,
      //TODO: Is this eager loaded now?
      key: [
        'auth.verified',
      ],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    BrowserAnimationsModule,
    UiSharedModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: APP_CONFIG, useValue: environment },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
