import { ChatMember } from '@hor/data-access-shared/interfaces/chatService';
import { LoadingSignalSetting, MessageSetting } from './global-events.state';

const globalEvents = '[Global Events]';


export namespace GlobalEventsActions {
  export class ShowBusyLoading {
    static readonly type = `${globalEvents} Show Busy Signal`;
    constructor(public setting: LoadingSignalSetting) {}
  }

  export class HideBusyLoading {
    static readonly type = `${globalEvents} Hide Busy Signal`;
    constructor() {}
  }

  export class ShowMessage {
    static readonly type = `${globalEvents} Show Message`;
    constructor(public setting: MessageSetting) {}
  }

  export class HideMessage {
    static readonly type = `${globalEvents} Hide Message`;
    constructor() {}
  }

  export class ShowVideoChatInvitation {
    static readonly type = `${globalEvents} Show Video Chat Invitation`;
    constructor(public fakeChatId: string, public videoChatId: string, public originator: string) {}
  }

  export class HideVideoChatInvitation {
    static readonly type = `${globalEvents} Hide Video Chat Invitation`;
    constructor() {}
  }
}
