const httpStatus = '[Http Status]';
export namespace HttpStatusActions {
  export class SetError {
    static readonly type = `${httpStatus} Set Error`;
    constructor(public error: string) {}
  }

  export class SetErrorOverride {
    static readonly type = `${httpStatus} Set Error Override`;
    constructor(public error: string) {}
  }

  export class ClearErrors {
    static readonly type = `${httpStatus} Clear Errors`;
  }

  export class SetLoading {
    static readonly type = `${httpStatus} Set Loading`;
    constructor(public loading: boolean) {}
  }
}
