import 'rxjs/add/operator/do';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@hor/app-config';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs/Observable';

import { Environment } from '../interfaces/environment';
import { LoaderService } from '../loader/loader.service';
import { HttpStatusActions } from '../states/http-status/http-status.action';
import { GlobalEventsActions } from '../states/global-events/global-events.action';
import { AuthService } from './auth.service';
import { first } from 'rxjs/operators';
import { AuthActions } from '../states/auth/auth.action';

//import { AuthService } from './auth.service';
@Injectable()
export class ApiInteceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private store: Store,
    @Inject(APP_CONFIG) private appConfig: Environment
  ) {}
  /*
  In order to prevent loading message apply following header
  {headers: new HttpHeaders().set('HOR-SHOWLOADING', 'false')}
  */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //base url, new endpoint us suffixed with /sabre
    const apiUrlBase = 'https://api.thebracespace.com/api/lecture';
    const apiUrlRegistration = 'https://api.thebracespace.com:443/api/lectureregistration'
    
    //const apiSecureUrlBase = this.appConfig.secureTravelApiUrl;
    //const apiTravelBase = this.appConfig.travelApiUrl;

    //console.log("****URL****" + req.url, req);

    let addJWT = req.url.startsWith(apiUrlBase) || req.url.startsWith(apiUrlRegistration);

    if (req.headers.has('HOR-JWT')) {
      if (req.headers.get('HOR-JWT') === 'false') addJWT = false;
      //console.log("removing")
      const headers = req.headers.delete('HOR-JWT');
      req = req.clone({ headers: headers });
    }

    this.store.dispatch(new HttpStatusActions.SetLoading(true));

    //get token from localStorage or sessionStorage depending on remember me setting, they should never be both present
    let JWT = sessionStorage.getItem('hor-token');
    if (localStorage.getItem('hor-token') !== '' && localStorage.getItem('hor-token') !== null) {
      JWT = localStorage.getItem('hor-token');
    }
    if (addJWT && JWT !== null && JWT !== '') {
      req = req.clone({
        setHeaders: {
          Authorization: JWT,
        },
      });
      this.store.dispatch(new AuthActions.Authenticated(true));
    }

    return next.handle(req).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.store.dispatch(new HttpStatusActions.SetLoading(false));
        }
      },
      (err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          this.store.dispatch(new HttpStatusActions.SetLoading(false));
          this.store.dispatch(new GlobalEventsActions.HideBusyLoading());

          if (err.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            this.authService.setUserData(null);
            this.store.dispatch(new AuthActions.Authenticated(false));
          }
          const message = err.error ? err.error.detail : err.message;
          this.store.dispatch(new HttpStatusActions.SetError(message));
        }
      }
    );
  }
}
