import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SiteLayoutViewModel } from './site-layout.view-model';

@Component({
  selector: 'hor-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
})
export class SiteLayoutComponent {
  siteLayout$: Observable<SiteLayoutViewModel>;

  
  constructor(router: Router, private route: ActivatedRoute) {
    this.siteLayout$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => {
        const url = new URL(e.url, location.href);
        const model = {
          isMain: url.pathname === '/',
          isRegistration: url.pathname.endsWith('/learntogive'),
          isRetainers: url.pathname.endsWith('/retainers')
        } as SiteLayoutViewModel;

        return model;
      })
    );
  }
  //TODO: refactor below class fetching
  showContainer = (siteLayout: SiteLayoutViewModel) =>
    siteLayout.isMain ||
    siteLayout.isRegistration ||
    siteLayout.isRetainers;

}
