import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, search: string): SafeHtml {
    if (!search || typeof search !== 'string') return text;

    const pattern = search
      ? search
          .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
          .split(' ')
          .filter(t => t.length > 0)
          .join('|')
      : undefined;
    const regex = new RegExp(pattern, 'gi');

    const html = search
      ? text.replace(regex, match => `<b>${match}</b>`)
      : text;

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
