/**
 * Create By Ashish Kadam
 * Email : ashishkadam83@gmail.com
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Constant } from '../constants/constant';
import { ApiProfileModel } from '../models/api.profile.model';
import { GetUserService } from './get-user.service';

//import { Router } from '@angular/router';
//import { AuthHeaderService } from './auth-header.service';
//import * as _ from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userDataSubject = new BehaviorSubject<any>(null);
  private authorizationSubject = new BehaviorSubject<boolean>(null);
  userData = this.userDataSubject.asObservable();

  //default to home page
  private currentStateSubject = new BehaviorSubject<string>('/');
  currentState = this.currentStateSubject.asObservable();

  constructor(private router: Router, private getUserService: GetUserService) {}

  navigateBackUser(dashboard) {
    const currentUrl = this.currentStateSubject.value;

    if (dashboard) this.router.navigate(['homes-dashboard', 'dashboard']);
    else this.router.navigateByUrl(currentUrl);
  }

  stateChange(state: string) {
    if (
      //if it's not one of the urls record the change
      !(state.indexOf('/login') > -1 || state.indexOf('/forgotpass') > -1)
    )
      this.currentStateSubject.next(state);
  }

  setUserData(data: any) {
    this.userDataSubject.next(data);
  }

  getUserData() {
    return this.userDataSubject.value;
  }

  //hack to expose subject until we move authentication to store
  getUserDataSubject() {
    return this.userDataSubject;
  }

  getAuthorizationSubject() {
    return this.authorizationSubject;
  }

  getUserImage() {
    const userData = this.getUserData() as ApiProfileModel;
    if (userData && userData.userDTO && userData.userDTO.imageUrl) {
      return userData.userDTO.imageUrl;
    }
    return Constant.defaultImageUrl;
  }

  getUserId(): number {
    const userData = this.getUserData() as ApiProfileModel;
    if (userData && userData.userDTO && userData.userDTO.id) {
      return userData.userDTO.id;
    }
    return -1;
  }

  getUserUuid(): string {
    const userData = this.getUserData() as ApiProfileModel;
    console.log(userData);
    if (userData && userData.userDTO && userData.userDTO.uuid) {
      return userData.userDTO.uuid;
    }
    return '';
  }

  isItMe(uuid: string): boolean {
    const userData = this.getUserData() as ApiProfileModel;
    if (userData && userData.userDTO && userData.userDTO.uuid) {
      return userData.userDTO.uuid.toLowerCase() == uuid.toLowerCase();
    }
    return false;
  }

  getUserName(): string {
    const userData = this.getUserData() as ApiProfileModel;
    if (
      userData &&
      userData.userDTO &&
      userData.userDTO.firstName &&
      userData.userDTO.lastName
    ) {
      return userData.userDTO.firstName + ' ' + userData.userDTO.lastName;
    }
    return '';
  }

  getIsHostSetting() {
    const userData = this.getUserData();
    if (userData && userData.userDTO) {
      return userData.userDTO.lastAccessMode === 'HOST';
    }
    return false;
  }

  isVerified(): boolean {    
    return  sessionStorage.getItem('hor-invite') == 'true'?true: false;
  }
  
  isAuthenticated(/*state: any*/): Observable<any> {
    let userData = null;
    let JWT = sessionStorage.getItem('hor-token');
    if (
      localStorage.getItem('hor-token') !== '' &&
      localStorage.getItem('hor-token') !== null
    )
      JWT = localStorage.getItem('hor-token');

    if (JWT !== null && JWT !== '') {
      userData = this.getUserData();
      if (!userData) {
        return this.getUserService.getUser().pipe(
          map((data) => {
            this.setUserData(data);
            return data;
          })
        );
      }
    } else {
      console.log('Not authenticated');
    }

    let observable = Observable.create((observer) => {
      observer.next(userData); // This method same as resolve() method from Angular 1
      observer.complete();
    });
    return observable;
  }
}
