import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { AlertModel } from '@hor/data-access-shared/models/alert.model';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'hor-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  faTimes = faTimes;
  constructor(
    public snackBarRef: MatSnackBarRef<AlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: AlertModel
  ) {}
}
